export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [4,6];

export const dictionary = {
		"/(ui)": [~8,[2],[3]],
		"/(ui)/_/(public)/NGBgPZYX3P59": [~24],
		"/(ui)/_/(admin)/admin": [10,[2,4,5],[3]],
		"/(ui)/_/(admin)/admin/feed": [~11,[2,4,5],[3]],
		"/(ui)/_/(admin)/admin/invites": [~12,[2,4,5],[3]],
		"/(ui)/_/(admin)/admin/links": [~13,[2,4,5],[3]],
		"/(ui)/_/(admin)/admin/users": [~14,[2,4,5],[3]],
		"/(ui)/_/(guest)/auth/login": [~20,[2,7],[3]],
		"/(ui)/_/(guest)/auth/login/code": [~21,[2,7],[3]],
		"/(ui)/_/(public)/auth/logout": [~26,[2,4],[3]],
		"/(ui)/_/(public)/features": [~27,[2,4],[3]],
		"/(ui)/_/(authed)/inbox": [~15,[2,6],[3]],
		"/(ui)/_/(guest)/invite/activate": [~22,[2,7],[3]],
		"/(ui)/_/(guest)/invite/request": [~23,[2,7],[3]],
		"/(ui)/_/(authed)/links": [~16,[2,6],[3]],
		"/(ui)/_/(authed)/links/[linkId]/stats": [~17,[2,6],[3]],
		"/(ui)/_/(authed)/me": [~18,[2,6],[3]],
		"/(ui)/_/(authed)/prefixes": [~19,[2,6],[3]],
		"/(ui)/_/(public)/terms": [~28,[2,4],[3]],
		"/(ui)/_/(public)/[..._]": [~25,[2,4],[3]],
		"/(ui)/[hash]": [~9,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';